/* eslint-disable react/display-name */
import React, { Suspense, Fragment, lazy } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
// import DocsLayout from 'src/layouts/DocsLayout';
// import MainLayout from "src/layouts/MainLayout";
import HomeView from "src/views/home/HomeView";
import LoadingScreen from "src/components/LoadingScreen";
import AuthGuard from "src/components/AuthGuard";
import GuestGuard from "src/components/GuestGuard";

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFoundView")),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: "/login",
    component: lazy(() => import("src/views/auth/LoginView")),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: "/register",
    component: lazy(() => import("src/views/auth/RegisterView")),
  },
  {
    exact: true,
    path: "/account/confirm-email",
    component: lazy(() =>
      import("src/views/account/AccountView/ConfirmAccount")
    ),
  },
  {
    path: "/",
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: "/account",
        component: lazy(() => import("src/views/account/AccountView")),
      },

      {
        exact: true,
        path: "/contacts",
        component: lazy(() => import("src/views/contact/ContactListView")),
      },
      {
        exact: true,
        path: "/contacts/create",
        component: lazy(() => import("src/views/contact/ContactCreateView")),
      },
      {
        exact: true,
        path: "/contacts/:contactId",
        component: lazy(() => import("src/views/contact/ContactDetailsView")),
      },
      {
        exact: true,
        path: "/contacts/:contactId/edit",
        component: lazy(() => import("src/views/contact/ContactEditView")),
      },
      {
        exact: true,
        path: "/support",
        component: lazy(() => import("src/views/support/SupportFeatureView")),
      },
      {
        exact: true,
        path: "/gifts",
        component: lazy(() => import("src/views/gift/GiftListView")),
      },
      {
        exact: true,
        path: "/gifts/create",
        component: lazy(() => import("src/views/gift/GiftCreateView")),
      },
      {
        exact: true,
        path: "/gifts/:giftId",
        component: lazy(() => import("src/views/gift/GiftDetailsView")),
      },
      {
        exact: true,
        path: "/gifts/:giftId/edit",
        component: lazy(() => import("src/views/gift/GiftEditView")),
      },
      {
        exact: true,
        path: "/tasks",
        component: lazy(() => import("src/views/task/TaskListView")),
      },
      {
        exact: true,
        path: "/tasks/create",
        component: lazy(() => import("src/views/task/TaskCreateView")),
      },
      {
        exact: true,
        path: "/commitments",
        component: lazy(() =>
          import("src/views/commitment/CommitmentListView")
        ),
      },
      {
        exact: true,
        path: "/commitments/create",
        component: lazy(() =>
          import("src/views/commitment/CommitmentCreateView")
        ),
      },
      {
        exact: true,
        path: "/commitments/:commitmentId/edit",
        component: lazy(() =>
          import("src/views/commitment/CommitmentEditView")
        ),
      },

      {
        exact: true,
        path: "/dashboard",
        component: lazy(() => import("src/views/reports/DashboardView")),
      },
      {
        exact: true,
        path: "/",
        component: lazy(() => import("src/views/reports/DashboardView")),
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
  {
    path: "*",
    layout: DashboardLayout,
    guard: AuthGuard,
    routes: [
      {
        exact: true,
        path: "/",
        component: HomeView,
      },
      {
        exact: true,
        path: "/pricing",
        component: lazy(() => import("src/views/pricing/PricingView")),
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
];

export default routes;
